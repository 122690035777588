/* MonthYearPicker.css */

/* .custom-calendar .rs-calendar-table-cell {
  font-size: 0;
  position: relative;
}

.custom-calendar .rs-calendar-table-cell:before {
  content: attr(data-month);
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-calendar .rs-calendar-table-cell[data-month="01"]::before {
  content: "Jan";
}

.custom-calendar .rs-calendar-table-cell[data-month="02"]::before {
  content: "Feb";
}

.custom-calendar .rs-calendar-table-cell[data-month="03"]::before {
  content: "Mar";
}

.custom-calendar .rs-calendar-table-cell[data-month="04"]::before {
  content: "Apr";
}

.custom-calendar .rs-calendar-table-cell[data-month="05"]::before {
  content: "May";
}

.custom-calendar .rs-calendar-table-cell[data-month="06"]::before {
  content: "Jun";
}

.custom-calendar .rs-calendar-table-cell[data-month="07"]::before {
  content: "Jul";
}

.custom-calendar .rs-calendar-table-cell[data-month="08"]::before {
  content: "Aug";
}

.custom-calendar .rs-calendar-table-cell[data-month="09"]::before {
  content: "Sep";
}

.custom-calendar .rs-calendar-table-cell[data-month="10"]::before {
  content: "Oct";
}

.custom-calendar .rs-calendar-table-cell[data-month="11"]::before {
  content: "Nov";
}

.custom-calendar .rs-calendar-table-cell[data-month="12"]::before {
  content: "Dec";
} */

.month-year-picker .rs-picker-toggle-value {
  text-indent: 2em;
  overflow: hidden;
  width: 5em;
}
