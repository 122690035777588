.select-dropdown__control {
  background-color: #fff !important;
}

.select-dropdown__single-value {
  color: blue !important;
}

.select-dropdown__dropdown-indicator {
  color: blue !important;
}

.select-dropdown {
  width: 100% !important;
}
