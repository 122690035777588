.react-select__control {
  background-color: #3700f0 !important;
}

.react-select__single-value {
  color: #fff !important;
}

.react-select__dropdown-indicator {
  color: white !important;
}
